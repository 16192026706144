var StatsModule = (function(){
	var params = {};
	var reqUrl = 'https://px.keepmypixel.com/pixel.aspx';
	function buildQueryString(params) {
		if(!!Object.keys(params).length) {
			var str = '';
			Object.keys(params).map(function(key, i) {
				if(i === 0) {
					str += '?'+key+'='+params[key];
				} else {
					str += '&'+key+'='+params[key];
				}
			});
			return str;
		}
	}
	
	function mergeObjects() {
		var resObj = {};
		for(var i=0; i < arguments.length; i += 1) {
			 var obj = arguments[i],
				 keys = Object.keys(obj);
			 for(var j=0; j < keys.length; j += 1) {
				 resObj[keys[j]] = obj[keys[j]];
			 }
		}
		return resObj;
	}
	
	return {
		getParams : function() {
			return params;
		},
		init: function(initParams) {
			params = mergeObjects(params, initParams);
		},
		sendPixel: function (overrideParams, callback) {
			var pixelParams = mergeObjects(params, overrideParams);
			var queryUrl = reqUrl+buildQueryString(pixelParams);
			var xhr = new XMLHttpRequest();
			xhr.onreadystatechange = function () {
				if (xhr.readyState === 4) {
					if(xhr.status === 200) {
						if(callback){
							callback()
						};
					} else {
						console.error('error');
					} 
				}
			}
			xhr.open('GET', queryUrl);
			xhr.send()
		}
	
	}
})();
let webroot = document.querySelector(":root");
let utilityPortalObj = {};
let hostname = window.location.hostname;
const docstep1id = "zipstep1";
const docstep2id = "zipstep2";
const docstep3id = "zipstep3";
const docstep4id = "zipstep4";
const docstep5id = "pdfstep5";
const docFileInputSelector = "#chooseFile";
const chooseDiffrentFileSelector = "#choose-diffrent-file";
const supportMailId = "support-id";
const termPageId = "termpg";
const privacyPageId = "privacypg";
const downloadBtnSelector = "#downloadBtn";
const convertBtnSelector = "#convertBtn";
const portalNameSelector = "[data-text=portal-name]";
const selectedFileNameSelector = "[data-text=selected-file-name]";
const searchBtnSelector = "#searchBtn";
const searchFormSelector = "#searchForm";
const searchInputSelector = "#query";
const cancelBtnSelector = "#cancelBtn";

var zipContent ;
if (hostname == "localhost") {
  hostname = "portal-qa.ziproar.com";
}
let apiSubDomain = "api";
if(hostname.includes("portal-qa")){
  apiSubDomain="api-qa";
}
utilityPortalObj.Config = {
  getCurrentUrl: function getCurrentUrl() {
    let currentUrl = hostname;
    currentUrl =
      currentUrl.split(".").length > 2
        ? currentUrl.split(".").slice(1, 3).join(".")
        : currentUrl;
    if (currentUrl === "0.0") return "";

    return currentUrl;
  },
  getApiConvertPdfUrl: function getApiConvertPdfUrl() {
    return ("//" + apiSubDomain + ".").concat(
      utilityPortalObj.Config.getCurrentUrl(),
      "/converter/post"
    );
  },
  getSearchUrl: function getSearchUrl(q, pid, userid) {
    return (
      utilityPortalObj.Config.portalValues.SearchUrl +
      "?q=" +
      q +
      "&publisherid=" +
      pid +
      "&userid=" +
      userid
    );
  },
  getConfigValueUrl: function getConfigValueUrl() {
    return ("//" + apiSubDomain + ".").concat(
      utilityPortalObj.Config.getCurrentUrl(),
      "/portal/get"
    );
  },
  portalValues: {},
};
utilityPortalObj.Storage = {
  GetItem: function GetItem(name) {
    return localStorage.getItem(
      utilityPortalObj.Config.getCurrentUrl().replace("com", "") + name
    );
  },
  SetItem: function SetItem(name, value) {
    localStorage.setItem(
      utilityPortalObj.Config.getCurrentUrl().replace("com", "") + name,
      value
    );
  },
  RemoveItem: function RemoveItem(name) {
    localStorage.removeItem(
      utilityPortalObj.Config.getCurrentUrl().replace("com", "") + name
    );
  },
};
utilityPortalObj.ValidationFunc = {
  IsUrlValid: function IsUrlValid(url) {
    return /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(
      url
    );
  },
  IsFieldValueEmpty: function IsFieldValueEmpty(value) {
    let isempty = false;

    if (value === "") {
      isempty = true;
    }

    return isempty;
  },
};

$(document).ready(function () {
  initPortal(function () {
    let statsData = getStatsData();
    statsData.name = utilityPortalObj.Config.portalValues.entityName;
    statsData.entity = 26;
    setTimeout(function () {
      StatsModule.init(statsData);
    }, 500);
    initHtml();
    bindEvents();
    getTimer();
  });
});

function initPortal(callback) {
  let currentUrl = hostname;
  currentUrl =
    currentUrl.split(".").length > 2
      ? currentUrl.split(".").slice(1, 3).join(".")
      : currentUrl;
  $.ajax({
    type: "GET",
    url: utilityPortalObj.Config.getConfigValueUrl(),
    success: function success(response) {
      let data = response.Values;
      utilityPortalObj.Config.portalValues = data;
      utilityPortalObj.appConfig = {};
      let staticAssets = "assets/";
      utilityPortalObj.appConfig.currentUrl = currentUrl;
      utilityPortalObj.appConfig.serverMediaUrl = ""
        .concat(data.base_url, "/")
        .concat(data.entityName, "/");
      utilityPortalObj.appConfig.staticImageUrl = "".concat(
        staticAssets,
        "img/"
      );
      //set style config
      setCssVariable();
      if (callback) callback();
    },
    error: function error(_error) {
      $("body").css("display", "block");
      $("#not-found-page").css("display", "block");
      console.log(_error);
    },
  });
}
function setCssVariable() {
  $("#zip .blockSec").css(
    "background",
    utilityPortalObj.Config.portalValues.ThemeColor
  );
  $(".defaultBtn").css(
    "background",
    utilityPortalObj.Config.portalValues.ButtonColor
  );

  $(".searchForm .search-btn").css(
    "background",
    utilityPortalObj.Config.portalValues.SearchButtonColor ||
      utilityPortalObj.Config.portalValues.ButtonColor ||
      "#009500"
  );
  if (utilityPortalObj.Config.portalValues.ClockColor) {
    $(".clock-widget").css(
      "color",
      utilityPortalObj.Config.portalValues.ClockColor
    );
  }
}
function initHtml() {
  $("head title").html(utilityPortalObj.Config.portalValues.Name);
  $("head").append(
    "<link rel='icon' type='image/ico' sizes='16x16' href='" +
      utilityPortalObj.appConfig.serverMediaUrl +
      "favi.ico'>"
  );

  $("#main-logo").attr(
    "src",
    utilityPortalObj.appConfig.serverMediaUrl + "logo_txt.png"
  );

  $(portalNameSelector).text(utilityPortalObj.Config.portalValues.Name);
  $(".footer #" + termPageId).attr(
    "href",
    "//" + utilityPortalObj.appConfig.currentUrl + "/terms.html"
  );
  $(".footer #" + privacyPageId).attr(
    "href",
    "//" + utilityPortalObj.appConfig.currentUrl + "/privacy.html"
  );
  $(".footer #" + supportMailId).attr(
    "href",
    "mailto:" +
      utilityPortalObj.Config.portalValues.SupportEmail +
      "?subject=support%20for%20" +
      utilityPortalObj.Config.portalValues.Name
  );
  $(".footer #" + supportMailId).html(
    utilityPortalObj.Config.portalValues.SupportEmail
  );
}
function bindEvents() {
  bindSearchEvents();
  bindDocEvents();
}
function bindSearchEvents() {
  $(searchBtnSelector).click(submitSearch);
  $(searchFormSelector).submit(submitSearch);
}
function submitSearch(e) {
  console.log(e);
  e.preventDefault();
  let query = $(searchInputSelector).val();
  if (query.length > 0) {
    let pid = StatsModule.getParams().pid;
    let userid = StatsModule.getParams().userid;
    let url = utilityPortalObj.Config.getSearchUrl(query, pid, userid);
    $(searchInputSelector).val("");
    console.log(url);
    window.open(url, "_blank");
  }
}
// time section start
function getTimer() {
  setInterval(function () {
    $("#clock-widget").html(formatAMPM().toUpperCase());
  }, 1000);
}
function formatAMPM() {
  console.log("formatAMPM");
  let date = new Date();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}
// time section end

// pdf section start
function bindDocEvents() {
  var zip = new JSZip();
  var zipSize = 0;
  var maxSize = 20 * 1024 * 1024;
  $(docFileInputSelector).change(async function () {
    if (Object.keys(zip.files).length != 0) {
      CleanZip();
    }
    for (var i = 0; i < this.files.length; i++) {
      var file = this.files[i];
      if (file.size > maxSize) {
        continue;
      }
      zipSize += file.size;
      zip.file(file.name, file);
    }
    if (Object.keys(zip.files).length != 0) {
      showDocStep(2);
    }
  });

  $("#converBtn").click(async function () {
    if (Object.keys(zip.files).length === 0) {
      zipSize = 0;
      return;
    }
    if (zipSize > maxSize) {
      CleanZip();
      zipSize = 0;
      showDocStep(1);
      return;
    }
    showDocStep(3);
    zip.generateAsync({ type: "blob" }).then(function (content) {
      showDocStep(4);
      zipContent=content;
    });
  });
  $("#downloadBtn").click(async function () {
    saveAs(zipContent, "MyZippedFiles.zip");
  });
  $(cancelBtnSelector).click(function () {
    if (Object.keys(zip.files).length != 0) {
      CleanZip();
    }
    zipContent=null;
    $(docFileInputSelector).val(null);
    showDocStep(1);
  });
  function CleanZip() {
    var numberOfFiles = Object.keys(zip.files).length;
    for (var i = 0; i < numberOfFiles; i++) {
      var fileName = zip.files[Object.keys(zip.files)[0]].name;
      zip.remove(fileName);
    }
  }
}

function showDocStep(stepnumber) {
  $("#" + docstep1id).hide();
  $("#" + docstep2id).hide();
  $("#" + docstep3id).hide();
  $("#" + docstep4id).hide();
  $("#" + docstep5id).hide();
  if (stepnumber == 1) {
    $("#" + docstep1id).show();
  } else if (stepnumber == 2) {
    $("#" + docstep2id).show();
  } else if (stepnumber == 3) {
    $("#" + docstep3id).show();
  } else if (stepnumber == 4) {
    $("#" + docstep4id).show();
  } else if (stepnumber == 4) {
    $("#" + docstep5id).show();
  }
}
// pdf section end

// common utility function start
function getStatsData() {
  let barcode = getCookie(
    "barcode",
    getParameterByName(
      "barcodeid",
      utilityPortalObj.Config.portalValues.defaultPid || "234004"
    )
  );
  let pid = barcode.substring(0, 5); // parse the pid only

  let uid = getCookie("uid", getParameterByName("userid", uuid()));
  let co = getCookie("co", getParameterByName("co", "tj"));
  let installDate = getCookie(
    "installDate",
    getParameterByName("installdate", "01-01-1970")
  );
  return {
    installDate: installDate,
    co: co,
    barcode: barcode,
    userid: uid,
    pid: pid,
  };
}

function uuid() {
  function randomDigit() {
    if (crypto && crypto.getRandomValues) {
      let rands = new Uint8Array(1);
      crypto.getRandomValues(rands);
      return (rands[0] % 16).toString(16);
    } else {
      return ((Math.random() * 16) | 0).toString(16);
    }
  }

  let crypto = window.crypto || window.msCrypto;
  return "xxxxxxxx-xxxx-4xxx-8xxx-xxxxxxxxxxxx".replace(/x/g, randomDigit);
}

function getCookie(name, def) {
  let v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : def;
}

function getBooleanFromConfig(value, defaultVal) {
  return typeof value !== "undefined" ? value === "true" : defaultVal;
}

function getParameterByName(name, def) {
  let url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return def;
  if (!results[2]) return def;
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
// common utility function end
